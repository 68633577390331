import {  TokenTransfer, U64Value } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRequests';
import  { useContext } from 'react';
import { Web3Context } from 'contexts/Web3Context';
import { FixedLengthArray } from 'typesS';

export const useBuyTicket = (numbers: FixedLengthArray<number[]>, bet: number) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrappedTickets, rewardTokenId, currentLotteryId } = useContext(Web3Context);


    const displayInfo = {
        processingMessage: 'Processing buy ticket transaction',
        errorMessage: 'An error has occurred while processing the transaction',
        successMessage: 'Ticket bought successfully'
    };

    const buyTicket = async () => {
        const contract = await getSmartContractObj();

        const x: number[] = [];
        numbers.map(value => x.push(value.valueOf()));
        // console.log('bet is', bet);

        const interaction = contract.methodsExplicit.buyTicket([new U64Value(x[0]), new U64Value(x[1]), new U64Value(x[2])]);

        const identifier = rewardTokenId;
        const numDecimals = 18;

        // console.log('bet is ' + bet);
        let transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), numDecimals);

        if (rewardTokenId === 'USDC-c76f1f') {
            transfer = TokenTransfer.fungibleFromAmount(identifier, bet.toLocaleString('en-US', { useGrouping: false }), 6);
        }
        
        // console.log('transfer is ' + transfer);

        const transaction = interaction
            .withNonce(account.nonce)
            .withSingleESDTTransfer(transfer)
            .withGasLimit(20_000_000)
            .withChainID(network.chainId)
            .buildTransaction();
        const sessionId = await sendAndSignTransactionsWrappedTickets(
            [transaction],
            displayInfo,
            currentLotteryId
        );
        return sessionId;
    };

    return buyTicket;
};
