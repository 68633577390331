import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useSendAndSign';
import { Web3Context } from 'contexts/Web3Context';

export const useWithdrawTokens = () => {
  const { account } = useGetAccountInfo();
  const {sendAndSignTransactionsWrapped} = useContext(Web3Context);
  
  const withdrawTokens = async () => {
    const contract = await getSmartContractObj();
    const interaction = contract.methodsExplicit.withdrawTokens();
    const displayInfo = {
      processingMessage: 'Processing withdraw tokens transaction',
      errorMessage: 'An error has occurred while processing the transaction',
      successMessage: 'Withdraw tokens successful'
    };
    const { sessionId, error } = await sendAndSignTransactionsWrapped(
      [
        interaction
          .withNonce(account.nonce)
          .withGasLimit(5_000_000)
          .withChainID(network.chainId)
          .buildTransaction()
      ],
      displayInfo
    );
    // setSessionId(sessionId);
    return sessionId;
  };

  return withdrawTokens;
};
