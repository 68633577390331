import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { LotteryInfo } from 'types/LotteryInfo';
import { WinningNumbers } from '../WinningNumbers/WinningNumbers';
import { convertToNumbers } from 'pages/Lottery/Lottery';
import styles from '../../lottery.module.scss';
import { Web3Context } from 'contexts/Web3Context';


export const PastLottery = ({ lottery, lotteryId, endTime }: { lottery: any, lotteryId: number, endTime: number }) => {
  //put button function claimReward
  //show button only when specific asset is not in user's wallet

  // console.log('lottery is', lottery.field2);
  // console.log('end time', new Date(endTime * 1000 + 1).toLocaleString());


  return (
    <>
      <div className={styles.timerContainer}>
        <div className={styles.historyFlex}>
          <div className={styles.boxTitle} >
            <div className={styles.historyTitleMod}>
              <h4>
                Lottery
              </h4>
              <div className={styles.historyId}>#{lotteryId + 1}</div>
            </div>
          </div>
          <div className={styles.historyTime}>
            <p>Drawn {new Date(endTime * 1000).toLocaleString()}</p>
          </div>
        </div>
        <div className={styles.barImg} />

        <div className={styles.timerBox} >
          <div className={styles.boxTitle} >
            <h4>
              Winning numbers
            </h4>
          </div>
          {lottery !== undefined && (
            <WinningNumbers numbers={convertToNumbers(lottery.field2)} />
          )}
        </div>
      </div>
    </>
  );
};
