import React from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';
import styles from '../layout.module.scss';

export const Footer = () => {
  return (
   
      <footer className={styles.footer}>
        <div className={'styles'}>
          <a
            {...{
              target: '_blank'
            }}
            className='d-flex align-items-center text-decoration-none'
            href='https://www.linkedin.com/company/zen-web3-solutions/'>
            © 2023 - Made by Zen Web3 Solutions.
          </a>
        </div>
        <div className={'styles d-flex justify-content-center'}>
          <a
            className={`${styles.url} me-2`}
            href='https://twitter.com/app_xbid'
            style={{fontSize: '25px', padding: '5px 5px'}}
            target='_blank'
          >
            <img src={'media/icons/twitter.png'} style={{width: '30px', height: '30px'}}/>
          </a>
          <a
            className={`${styles.url} me-2`}
            href='https://t.me/xbid_mvx'
            style={{fontSize: '25px', padding: '5px 5px'}}
            target='_blank'
          >
            <img src={'media/icons/telegram.png'} style={{width: '35px', height: '35px'}}/>
          </a>
          <a
            className={`${styles.url} me-2`}
            href='https://www.coingecko.com/en/coins/xbid'
            style={{fontSize: '25px', padding: '5px 5px'}}
            target='_blank'
          >
            <img src={'media/icons/coingecko.png'} style={{width: '42px', height: '42px'}}/>
          </a>
          <a
            className={`${styles.url} me-2`}
            href='https://xbid.gitbook.io/xbid/'
            style={{fontSize: '25px', padding: '5px 5px'}}
            target='_blank'
          >
            <img src={'media/icons/book.png'} style={{width: '42px', height: '42px'}}/>
          </a>
          <a
            className={`${styles.url2} ${styles.subOptionsContainer} mt-1 mb-1 me-2 text-center`}
            href='https://bridge.onefinity.network/'
            style={{fontSize: '13px', padding: '5px 5px', color: 'white'}}
            target='_blank'
          >
            Solana Bridge
          </a>
        </div>
        <div className={'styles'}>
          xLottery - 2023
        </div>
      </footer>

  );
};
