import React, { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Typography } from '@mui/material';
import { TicketAndStake, Web3Context } from 'contexts/Web3Context';
import * as scRequests from '../../../../contexts/scRequests';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { Address } from '@multiversx/sdk-core/out';
import { WinningNumbers, getCorrectIndex } from '../WinningNumbers/WinningNumbers';
import { Carousel } from 'react-responsive-carousel';
import { useClaimReward } from 'contexts/hooks/transactions/useClaimReward';
import styles from '../../lottery.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { TicketCardNumber } from '../TicketCard/TicketCardNumber';
import BigNumber from 'bignumber.js';


const PastWinningTickets = () => {
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedElementIndex, setSelectedElementIndex] = useState(-1); // -1 means no element is selected
    const [selectedLotteryId, setSelectedLotteryId] = useState(0);

    const handleElementClick = (index: number) => {
        setSelectedElementIndex(index);
        setSelectedLotteryId(context.pastWinningLotteries[index].field0.toNumber());
    };

    const { address } = useGetAccountInfo();
    const context = useContext(Web3Context);

    const claimReward = useClaimReward(selectedLotteryId);

    // const handleClaimReward = (selectedIndex: number) => {
    //     setSelectedLotteryId(context.pastWinningLotteries[selectedIndex].field0.toNumber());
    //     // console.log(context.pastWinningLotteries[selectedIndex].field0.toNumber());
    //     // claimReward();
    // };

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };

    // console.log(context.pastWinningLotteries);

    // console.log('PAST WINNING', context.pastWinningLotteries); //length is 1 but no element
    // console.log(context.pastWinningLotteries[0].field1.map((entry2: any, i: any) => (entry2) );

    return context.pastWinningLotteries.length === 0 || context.pastWinningLotteries[0].field0 === undefined ? (
        <>
            <div className={styles.historyTime}> No past winning tickets </div>
        </>
    ) : (
        <>
            <a className={styles.buttonLight} onClick={handleOpen}>
                Past winning tickets
            </a>
            <div className={`modal ${show ? 'show' : ''}`} tabIndex={-1} role="dialog" style={{ display: show ? 'block' : 'none' }}>
                <div className={styles.modalDialogPastWinning} role="document">
                    <div className={`modal-content  ${styles.pastWinningModal} `}>
                        <div className='d-flex flex-row justify-content-between align-items-center me-2'>
                            <h3 className={styles.historyTitle}>Your past winning tickets</h3>
                            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={handleClose}></button>
                        </div>
                        <div className={styles.pastWinningElementsStack}>
                            {
                                context.pastWinningLotteries.map((entry, index) => (
                                    <div key={`pastwinninglotteries-${index}`} className={`${styles.pastWinningElement} ${selectedElementIndex === index ? styles.selected : ''}`} onClick={() => handleElementClick(index)}>
                                        {entry.field0 !== undefined &&
                                            entry.field1 !== undefined && (
                                                <>
                                                    <p>Lottery #{entry.field0.toNumber()}</p>
                                                    <div className={styles.carouselWrapperPastWinning}>
                                                        <div id={`bootstrapCarousel${index}`} className="carousel slide" data-bs-ride="carousel">
                                                            <div className="carousel-inner">
                                                                {
                                                                    entry.field1.map((entry2: any, i: any) => (
                                                                        <>
                                                                            <div key={`past-winning-${index}-${i}`} className={`carousel-item ${i === 0 ? 'active' : ''}`}>

                                                                                <p className="text-secondary">User stake: {new BigNumber(
                                                                                    entry2.user_stake
                                                                                ).dividedBy(new BigNumber(10).pow(18)).toNumber()}{' '}
                                                                                    {/* {context.rewardTokenId} */}
                                                                                    {entry.field2 !== undefined && (
                                                                                        <>
                                                                                            {entry.field2.toString()}
                                                                                        </>
                                                                                    )}
                                                                                </p>

                                                                                <div className="d-flex w-100 justify-content-between flex-row">

                                                                                    {[entry2.first.toNumber(), entry2.second.toNumber(), entry2.third.toNumber()].map((number: any, indexInner: any) => (
                                                                                        <div key={`ticket-card-${index}-${i}-${indexInner}`} className={styles.numbersRight}>
                                                                                            {indexInner % 2 === 0 ? (
                                                                                                <div className={`text-white  ${styles.try1} ${getCorrectIndex(indexInner)}`}>
                                                                                                    <div className={styles.dateText}>
                                                                                                        <TicketCardNumber number={number} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            ) : (
                                                                                                <div className={`text-white  ${styles.try2} ${getCorrectIndex(indexInner)}`}>
                                                                                                    <div className={styles.dateText}>
                                                                                                        <TicketCardNumber number={number} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            )}

                                                                                        </div>


                                                                                    ))}
                                                                                </div>


                                                                            </div>
                                                                        </>
                                                                    ))}
                                                            </div>

                                                        </div>
                                                        <div className={styles.carouselNavigationPastWinning}>
                                                            <div className={`${styles.carouselArrowLeft} carousel-control-prev  `} data-bs-target={`#bootstrapCarousel${index}`} data-bs-slide="prev">
                                                                <FontAwesomeIcon icon={faChevronLeft} />
                                                            </div>
                                                            <div className={`${styles.carouselArrowRight} carousel-control-next `} data-bs-target={`#bootstrapCarousel${index}`} data-bs-slide="next">
                                                                <FontAwesomeIcon icon={faChevronRight} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        <div>
                                            {selectedElementIndex === index && (
                                                <div>
                                                    <a className={styles.buttonLight} onClick={claimReward}>
                                                        Claim Reward
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <a className={styles.buttonDark} onClick={handleClose}>
                            Close
                        </a>
                    </div>
                </div>
            </div>

        </>
    );
};

export default PastWinningTickets;

{/* <a  className={styles.buttonLight} onClick={handleOpen}>
                Past winning tickets
            </a>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Your past winning tickets</DialogTitle>
                <DialogContent sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, maxHeight: 400, overflowY: 'auto' }}>
                    {context.pastWinningLotteries.map((entry: [number, TicketAndStake[]], index: any) => (

                        <Card key={index} sx={{ flex: '0 0 calc(65%)', marginBottom: 2 }}>
                            <CardContent>
                                {(context.pastWinningLotteries[index].field0 !== undefined && context.pastWinningLotteries[index].field1 !== undefined)
                                    &&
                                    (
                                        <>
                                            Lottery #{context.pastWinningLotteries[index].field0.toNumber()}
                                            <Typography color="text.secondary">description here</Typography>
                                            <Carousel
                                                autoPlay={false}
                                                interval={3000}
                                                infiniteLoop={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                animationHandler={'slide'}
                                                swipeable={true}
                                                onChange={handleCarouselChange}
                                            >
                                                {context.pastWinningLotteries[index].field1.map((entry2: TicketAndStake, index: any) => (
                                                    <Card key={index}>
                                                        <WinningNumbers numbers={[entry2.first, entry2.second, entry2.third]} />
                                                        <a
                                                            href='#'
                                                            className='btn btn-secondary mt-2 mb-2'
                                                            onClick={claimReward}
                                                            title='Claim reward'
                                                        >
                                                            Claim Reward
                                                        </a>
                                                    </Card>
                                                ))}
                                            </Carousel>
                                        </>

                                    )
                                }

                            </CardContent>
                        </Card>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}

{/* <div
                                                        id={`carousel-${index}`}
                                                        className="carousel slide"
                                                        data-bs-ride="carousel"
                                                        data-bs-interval="3000"
                                                    >
                                                        <div className="carousel-inner">
                                                            {context.pastWinningLotteries[index].field1.map((entry2: TicketAndStake, index: any) => (
                                                                <div
                                                                    key={index}
                                                                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                                                >
                                                                    <WinningNumbers numbers={[entry2.first, entry2.second, entry2.third]} />
                                                                    <a
                                                                        href="#"
                                                                        className="btn btn-secondary mt-2 mb-2"
                                                                        onClick={claimReward}
                                                                        title="Claim reward"
                                                                    >
                                                                        Claim Reward
                                                                    </a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <a className="carousel-control-prev" href={`#carousel-${index}`} role="button" data-bs-slide="prev">
                                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Previous</span>
                                                        </a>
                                                        <a className="carousel-control-next" href={`#carousel-${index}`} role="button" data-bs-slide="next">
                                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Next</span>
                                                        </a>
                                                    </div> */}