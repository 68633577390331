import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks/account';
import { sendTransactions } from '@multiversx/sdk-dapp/services/transactions/sendTransactions';
import { network } from 'config';
import { getSmartContractObj } from 'contexts/scRequests';
import React, { useContext } from 'react';
import { sendAndSignTransactions } from './useSendAndSign';
import { Web3Context } from 'contexts/Web3Context';
import { TokenIdentifierValue } from '@multiversx/sdk-core/out';

export const useWithdrawSpecificToken = (tokenId: string) => {
    const { account } = useGetAccountInfo();
    const { sendAndSignTransactionsWrapped } = useContext(Web3Context);

    const withdrawSpecificToken = async () => {
        const contract = await getSmartContractObj();
        const interaction = contract.methodsExplicit.withdrawAllSpecificToken([new TokenIdentifierValue(tokenId)]);
        const displayInfo = {
            processingMessage: 'Processing withdraw specific token transaction',
            errorMessage: 'An error has occurred while processing the transaction',
            successMessage: 'Withdraw specific token successful'
        };
        const { sessionId, error } = await sendAndSignTransactionsWrapped(
            [
                interaction
                    .withNonce(account.nonce)
                    .withGasLimit(5_000_000)
                    .withChainID(network.chainId)
                    .buildTransaction()
            ],
            displayInfo
        );
        // setSessionId(sessionId);
        return sessionId;
    };

    return withdrawSpecificToken;
};
