import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Ticket } from 'Ticket';
import { TicketCardNumber } from '../TicketCard/TicketCardNumber';
import BigNumber from 'bignumber.js';
import styles from '../../lottery.module.scss';
import './index.css';



export const WinningNumbers: React.FC<{ numbers: number[] }> = ({
  numbers
}) => {
  return (
    <div className={`${styles.timerContainer}`}>

      <div className="row row-cols-6 g-2" style={{ gap: '10px', justifyContent: 'space-between' }}>
        {numbers.map((number, index) => (


          <div className="col-2 d-flex justify-content-center align-items-center" key={number}>
            <div className={styles.numbers}>
              {index % 2 === 0 ? (
                <p className={`text-white ${styles.try1} ${getCorrectIndex(index)}`} >{number}</p>

              ) : (
                <p className={`text-white ${styles.try2} ${getCorrectIndex(index)}`}>{number}</p>

              )}

            </div>
          </div>

        ))}
      </div>
    </div>
    //     <Card>
    //   <CardContent>
    //     <Grid container spacing={2}>
    //       {numbers.map((number, index) => (
    //         <Grid item xs={2} key={number.valueOf()}> {/* Here, xs={2} means each element takes 2/12 (1/6) of the row */}
    //           <Box sx={{ backgroundColor: 'secondary.main', borderRadius: 999, width: 40, height: 40 }}>
    //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    //               <Typography color="common.white">{number}</Typography>
    //             </Box>
    //           </Box>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </CardContent>
    // </Card>

  );
};

export const getCorrectIndex = (currentIndex: number) => {

  const sphereIndex = currentIndex % 6;
  const sphereClass = `back${sphereIndex + 1}`;
  return sphereClass;
};

