
// export const contractAddress =
//   'erd1qqqqqqqqqqqqqpgq6wg3xkvv788magv4lqhd9hc4qgq00dctesgqgpwcds';

export const contractAddress = 'erd1qqqqqqqqqqqqqpgqz33dlmjlk8y77z8l82dhkegfdfkf43lcesgqa4qwhc';

export const dAppName = 'xLottery';

// export const adminAddress = 'erd10x2dcvqxvgf8urkaanl7cak4ynhewjt8q5xgl0kssngjnjp40ytssdfd8k';
// export const adminAddress = 'erd1fegge5067awlw94ksycw0gfk8z2zzz2l3rnesjuytz8ampucsnwq5ns2hn';
export const adminAddress = 'erd17n4gpw7gkjy32fkq2nupqapluqmfhfwyc8kkm6ztjxg9qxdnesgq822ftt';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = '9b1a9564f91cb659ffe21b73d5c4e2d8';

// export const network = {
//   id: 'mainnet',
//   name: 'Mainnet',
//   egldLabel: 'EGLD',
//   walletAddress: 'https://wallet.multiversx.com',
//   apiAddress: 'https://api.multiversx.com',
//   gatewayAddress: 'https://gateway.multiversx.com',
//   explorerAddress: 'http://explorer.multiversx.com',
//   graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
//   skipFetchFromServer: true,
//   chainId: '1'
// };


//main net blastapi
export const network = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.multiversx.com',
  apiAddress: 'https://elrond-api.public.blastapi.io',
  gatewayAddress: 'https://elrond-mainnet.public.blastapi.io',
  explorerAddress: 'http://explorer.multiversx.com',
  graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
  skipFetchFromServer: true,
  chainId: '1'
};


// export const network = {
//   id: 'devnet',
//   name: 'devnet',
//   egldLabel: 'EGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   // apiAddress: 'https://elrond-api-devnet.public.blastapi.io',
//   apiAddress: 'https://devnet-api.multiversx.com',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
//   skipFetchFromServer: true,
//   chainId: 'D'
// };

// export const network = {
//   id: 'devnet',
//   name: 'devnet',
//   egldLabel: 'EGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   apiAddress: 'https://elrond-api-devnet.public.blastapi.io',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://exchange-graph.multiversx.com/graphql',
//   skipFetchFromServer: true,
//   chainId: 'D'
// };

export const apiTimeout = 20000; //6000 default value
export const transactionSize = 15;
export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];
