import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import styles from '../../lottery.module.scss';
import './index.css';

export interface PickNumberInputProps {
  index: number,
  value: number,
  isSelected: boolean,
  onClick: (value: number) => void;
  isClickable: boolean;
}

export const PickNumberInput: React.FC<PickNumberInputProps> = (props: PickNumberInputProps) => {
  const backgroundColor = props.isSelected ? 'lightButton' : 'darkButton';
  const textColor = 'white';
  const borderColor = props.isSelected ? 'transparent' : 'transparent';
  const layoutDirection = props.index % 2 === 0 ? 'try1' : 'try2';

  //fix this, take diff style in relation with the index

  const handleOnClick = () => {
    if (props.isClickable) {
      props.onClick(props.value);
    }
  };



  return (
    <button
      className={`btn ${backgroundColor} ${layoutDirection}`}
      style={{
        borderColor: borderColor,
        borderWidth: 1,
        borderStyle: 'groove',
        borderRadius: 999,
        width: 40,
        height: 40,
      }}
      onClick={handleOnClick}
    >
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <p className={`m-0 text-${textColor}`}>{props.value}</p>
      </div>
    </button>

  );
};


 // <IconButton
    //     sx={{
    //       backgroundColor: backgroundColor,
    //       borderColor: textColor,
    //       borderWidth: 2,
    //       borderStyle: 'solid',
    //       borderRadius: 999,
    //       width: 40,
    //       height: 40,
    //       '&:hover': {
    //         backgroundColor: backgroundColor, borderColor: textColor
    //       }
    //     }}
    //     onClick={handleOnClick}>
    //     <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
    //         <Typography color={textColor}>{props.value}</Typography>
    //     </Stack>
    // </IconButton>
