import * as React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AuthRedirectWrapper, ExtensionLoginButton, Layout, LedgerLoginButton, OperaWalletLoginButton, WalletConnectLoginButton, WebWalletLoginButton } from 'components';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import styles from './home.module.scss';
import styleLayout from '../../components/Layout/layout.module.scss';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiversXIcon from '../../assets/img/multiversx-symbol-black.svg';
import LedgerIcon from '../../assets/img/Ledger-symbol-black.svg';
import { ReactComponent as XBidLogo } from '../../assets/img/logo.svg';
import { Footer } from 'components/Layout/Footer';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks/account';

export const HomePage = () => {
  const isLoggedIn = useGetIsLoggedIn();

  // console.log('is logged in home page', isLoggedIn);

  const commonProps = {
    callbackRoute: routeNames.lottery,
    nativeAuth: true // optional
  };

  if (isLoggedIn) {
    return <Navigate to={routeNames.lottery} />;
  }


  return (
    <>
      <div className={styleLayout.containerLogin}>

        <div className={styles.container}>
          <article className={styles.loginBox}>
            <XBidLogo className={styles.logo} />
            <h1 className={styles.title}>Welcome to xLottery</h1>
            <p className={styles.text}>
              Lottery dApp, login using your MultiversX wallet
            </p>
            <div className={styles.bar}>
              <span className={`${styles.barImg}`} />
              <div className={`${styles.barTitle}`}>
                Login with
              </div>
              <span className={`${styles.barImg}`} />
            </div>

            <div className={styles.btns}>

              <ExtensionLoginButton
                buttonClassName={styles.btn}
                {...commonProps}
              >
                <img
                  alt=""
                  height={0}
                  width={0}
                  src={MultiversXIcon}
                  className={styles.btnLogo}
                />
                <div className={styles.btnText}>
                  MultiversX DeFi Wallet
                </div>

                <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
              </ExtensionLoginButton>

              <WebWalletLoginButton
                buttonClassName={styles.btn}
                {...commonProps}
              >
                <img
                  alt=""
                  height={0}
                  width={0}
                  src={MultiversXIcon}
                  className={styles.btnLogo}
                />
                <div className={styles.btnText}>
                  MultiversX Web Wallet
                </div>

                <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
              </WebWalletLoginButton>

              <LedgerLoginButton
                buttonClassName={styles.btn}
                {...commonProps}
              >
                <img
                  alt=""
                  height={0}
                  width={0}
                  src={LedgerIcon}
                  className={styles.btnLogo}
                />
                <div className={styles.btnText}>
                  Ledger
                </div>

                <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
              </LedgerLoginButton>

              <WalletConnectLoginButton
                buttonClassName={styles.btn}
                {...commonProps}
              >
                <img
                  alt=""
                  height={0}
                  width={0}
                  src={MultiversXIcon}
                  className={styles.btnLogo}
                />
                <div className={styles.btnText}>
                  xPortal App
                </div>

                <FontAwesomeIcon icon={faArrowRightLong} className={styles.btnIcon} />
              </WalletConnectLoginButton>


            </div>
          </article>
        </div>

        <Footer />
      </div>

    </>

  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
