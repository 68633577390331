import { Spinner } from 'react-bootstrap';

export const LoadingScreen = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div
          className='col-12 d-flex justify-content-center align-items-center'
          style={{ marginTop: '20%' }}
        >
          <Spinner animation='grow'/>
        </div>
        <div className='col-12 d-flex justify-content-center align-items-center mt-3'>
          <h3>Loading..</h3>
        </div>
      </div>
    </div>
  );
};
