import React from 'react';
import { ContractDetails } from './ContractDetails';
import { TransactionsCard } from './TransactionsCard';
import { LotteryDetails } from './LotteryDetails';
import { Layout } from 'components/Layout/Layout';

export const Admin = () => {
    return (
        <>
            <Layout >
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h1 className='mt-5 mb-5'>
                               Lottery admin panel
                            </h1>
                        </div>

                        <div className='col-lg-6 col-sm-12'>
                            <ContractDetails />
                        </div>

                        <div className='col-lg-6 col-sm-12'>
                            <LotteryDetails />
                        </div>

                        <div className='col-12 mt-3'>
                            <TransactionsCard />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};