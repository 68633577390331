import { dAppName } from 'config';
import { RouteType } from 'types';
import { withPageTitle } from './components/PageTitle';

import { HomePage } from './pages/Home/Home';
import { LotteryPage } from 'pages/Lottery';
import { Admin } from 'pages/Admin';

export const routeNames = {
  home: '/',

  unlock: '/unlock',
  lottery: '/lottery',
  admin: '/admin'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.unlock,
    title: 'Unlock',
    component: HomePage
  },
  {
    path: routeNames.home,
    title: 'Home',
    component: LotteryPage
  },
  {
    path: routeNames.lottery,
    title: 'Lottery',
    component: LotteryPage
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: Admin
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • MultiversX ${dAppName}`
    : `MultiversX ${dAppName}`;

  //const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent
  };
});
