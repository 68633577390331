import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardContent, CardHeader, Divider, IconButton, Stack } from '@mui/material';
import { Ticket } from 'Ticket';
import { TicketCardNumber } from './TicketCardNumber';
import { PickTicketDialog } from './PickTicketDialog';
import { FixedLengthArray } from 'typesS';
import styles from '../../lottery.module.scss';
import { getCorrectIndex } from '../WinningNumbers/WinningNumbers';
import '../WinningNumbers/index.css';

export interface TicketCardProps {
  ticket: Ticket;
  onTicketChange: (ticket: Ticket) => void;
}

export const TicketCard: React.FC<TicketCardProps> = (props: TicketCardProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSave = (numbers: FixedLengthArray<[number, number, number]>) => {
    props.onTicketChange(new Ticket(numbers));
  };

  // console.log('TicketCard: ticket is', props.ticket.numbers);
  return (

    <div className={`${styles.timerContainer}`}>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className={styles.historyTime}>
          <div className=''> Your numbers</div>
        </div>
        <button className={styles.pen} onClick={handleClickOpen}>
          <EditIcon />
        </button>
      </div>
      <div className="d-flex w-100 justify-content-between flex-row">
        {props.ticket.numbers.map((number, index) => (
          <div key={index} className={styles.numbersRight}>
            {index % 2 === 0 ? (
              <div className={`text-white  ${styles.try1} ${getCorrectIndex(index)}`}>
                <div className={styles.dateText}>
                  <TicketCardNumber number={number} />
                </div>
              </div>

            ) : (
              <div className={`text-white  ${styles.try2} ${getCorrectIndex(index)}`}>
                <div className={styles.dateText}>
                  <TicketCardNumber number={number} />
                </div>
              </div>

            )}

          </div>
        ))}

      </div>
      <PickTicketDialog open={openDialog} onClose={handleClose} save={handleSave} />
    </div>
    // <Card>
    //     <CardHeader
    //     title="Your numbers"
    //     action={
    //         <IconButton color="primary" onClick={handleClickOpen}>
    //             <EditIcon />
    //         </IconButton>
    //     }
    //     />
    //     <Divider />
    //     <CardContent>
    //         <Stack direction="row" gap={2} justifyContent="space-around">
    //             {props.ticket.numbers.map((number) => (
    //                 <TicketCardNumber key={number} number={number} />
    //             ))}
    //         </Stack>
    //     </CardContent>
    //     <PickTicketDialog open={openDialog} onClose={handleClose} save={handleSave} />
    // </Card>
  );
};
