import React, {useEffect, useRef, useState} from 'react';
import {
  faDice,
  faShoppingCart,
  faHammer,
  faQuestion,
  faSignIn,
  faSignOut,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { logout } from 'helpers';
import { useGetAccount, useGetIsLoggedIn } from 'hooks';
import { ReactComponent as IxBidLogo } from '../../../assets/img/logo.svg';
import * as config from '../../../config';
import { AiOutlineBars } from 'react-icons/ai';
import styles from '../layout.module.scss';
import Fade from 'react-bootstrap/Fade';
import { Home } from '../../../pages/Home/Home';
import { useNavigate } from 'react-router-dom';

export const Navbar = () => {
  const isLoggedIn = useGetIsLoggedIn();
  const logoStyles: React.CSSProperties = {
    width: '72px',
    height: '72px',
    marginRight: '8px'
  };

  const logoStylesSmaller: React.CSSProperties = {
    width: '1px',
    height: '1px',
    marginRight: '0px',
    marginLeft: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    opacity: '0'
  };

  const [openNav, setOpenNav] = useState(false);
  const ref = useRef(null);
  const buyRef = useRef<HTMLDivElement | null>(null);
  const { address } = useGetAccount();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
  };

  const [showBuyXBidOptions, setShowBuyXBidOptions] = useState(false);
  const handleBuyXBidOptionClick = (option: 'solana' | 'multiversx') => {
    if (option === 'solana') {
      window.open('https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6D1PzpkcAQEe8xojVt4DkVoFExnwDtfgoewsG8Htgmn2&fixed=in', '_blank');
    } else if (option === 'multiversx') {
      window.open('https://swap.onedex.app/swap?firstToken=EGLD&secondToken=XBID-c7e360', '_blank');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const buyElement = document.getElementById('buyElement');

      if (buyElement && !buyElement.contains(event.target as Node)) {
        setShowBuyXBidOptions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.logo}>
        <IxBidLogo style={logoStylesSmaller} />
      </div>

      {/*Desktop screen*/}
      <div className={styles.d_none}>
        <div className={styles.flex}>
          <div className={styles.logo}>
            <IxBidLogo style={logoStyles} />
            <div className="">l xLottery</div>
          </div>
          <div className={styles.urls}>
            <Link
              to={'/lottery'}
              className={styles.url}
              style={{position: 'relative'}}
            >
              <div className={window.location.pathname === '/lottery' ? styles.urlText : ''}>
                <img
                  src="/media/icons/dice.svg"
                  alt="styles"
                  className={styles.defaultImage}
                  style={{marginLeft: '-35px'}}
                />
                <img
                  src="/media/icons/dice_blue.svg"
                  alt="styles"
                  className={styles.hoverImage}
                  style={{marginLeft: '-35px'}}
                />
                Lottery
              </div>
            </Link>
            <a
              className={styles.url}
              href="https://www.xbid.app/"
            >
              <FontAwesomeIcon icon={faHammer} />
              Auction
            </a>
            {address === config.adminAddress && (
              <Link
                to='/admin'
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser}/>
                <div className={window.location.pathname === '/admin' ? styles.urlText : ''}>
                  Admin
                </div>
              </Link>
            )}
            <div
              className={`${styles.url} ${styles.subOptionsContainer}`}
              style={{ position: 'relative'}}
              onClick={() => setShowBuyXBidOptions(!showBuyXBidOptions)}
              id={'buyElement'} ref={buyRef}
            >
              <div  className={styles.divSubOptions}>
                <FontAwesomeIcon icon={faShoppingCart} className={'me-2'} />
                Buy xBid
              </div>
              <Fade in={showBuyXBidOptions}>
                <div className={styles.subOptions}>
                  <div onClick={() => handleBuyXBidOptionClick('solana')} style={{whiteSpace: 'nowrap'}}>
                    {'◆'} Solana
                  </div>
                  <div onClick={() => handleBuyXBidOptionClick('multiversx')} style={{whiteSpace: 'nowrap'}}>
                    {'◆'} MultiversX
                  </div>
                </div>
              </Fade>
            </div>
          </div>

          <div className={styles.logout}>
            {isLoggedIn ? (
              <div onClick={handleLogout} className={styles.url}>
                <FontAwesomeIcon icon={faSignOut} />
                Log Out
              </div>
            ):(
              <div onClick={() => navigate('/unlock')} className={styles.url}>
                <FontAwesomeIcon icon={faSignIn} />
                Log In
              </div>
            )}            
          </div>
        </div>
      </div>

      {/* mobile screen */}
      <div className={`${styles.flex} ${styles.barIcon}`}>
        <div className={styles.logo}>
          <IxBidLogo style={logoStyles} />
          <div className=''>l xLottery</div>
        </div>

        <div
          ref={ref}
          onClick={() => setOpenNav(!openNav)}
          className={styles.bar}
        >
          <AiOutlineBars />

          <div className={`${styles.urls} ${openNav ? styles.show : ''}`}>

            <Link
              onClick={() => setOpenNav(!openNav)}
              to='/lottery'
              className={styles.url}
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              <FontAwesomeIcon icon={faDice} style={{minWidth: '15px', marginLeft: '-2px'}} />
              <div className={window.location.pathname === 'lottery' ? styles.urlText : ''}>
                Lottery
              </div>
            </Link>
            <a
              style={{ paddingLeft: '25px !important' }}
              className={styles.url}
              href="https://www.xbid.app/">
              <FontAwesomeIcon icon={faHammer} style={{minWidth: '15px'}} />
              Auction
            </a>
            {address === config.adminAddress && (
              <Link
                to='/admin'
                className={styles.url}
              >
                <FontAwesomeIcon icon={faUser} style={{minWidth: '15px'}} />
                <div className={window.location.pathname === '/admin' ? styles.urlText : ''}>
                  Admin
                </div>
              </Link>
            )}
            <a
              className={styles.url}
              href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6D1PzpkcAQEe8xojVt4DkVoFExnwDtfgoewsG8Htgmn2&fixed=in">
              <FontAwesomeIcon icon={faShoppingCart} style={{minWidth: '15px'}} />
              <span style={{whiteSpace: 'nowrap'}}>Buy xBid - Solana</span>
            </a>
            <a
              className={styles.url}
              href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=6D1PzpkcAQEe8xojVt4DkVoFExnwDtfgoewsG8Htgmn2&fixed=in">
              <FontAwesomeIcon icon={faShoppingCart} style={{minWidth: '15px'}} />
              <span style={{whiteSpace: 'nowrap'}}>Buy xBid - MultiversX</span>
            </a>
            <div className={styles.logout}>
              {isLoggedIn ? (
                <div onClick={handleLogout} className={styles.url}>
                  <FontAwesomeIcon icon={faSignOut} style={{minWidth: '15px'}} />
                  Log Out
                </div>
              ):(
                <div onClick={() => navigate('/unlock')} className={styles.url}>
                  <FontAwesomeIcon icon={faSignIn} style={{minWidth: '15px'}} />
                  Log In
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};