import {
  AbiRegistry,
  Address,
  AddressValue,
  ResultsParser,
  SmartContract,
  U64Value
} from '@multiversx/sdk-core';

import { network, contractAddress } from 'config';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import contractAbi from './abi/lottery-sc.abi.json';
import { initialTicketAndStake } from '../contexts/Web3Context';

export const Provider = new ProxyNetworkProvider(network.gatewayAddress, {
  timeout: 20000 //modify this for testing, change api entirely with blast api
});
export const resultsParser = new ResultsParser();

export const getSmartContractObj = async () => {
  const abiRegistry = await AbiRegistry.create(formatAbiJson(contractAbi));
  return new SmartContract({
    address: new Address(contractAddress),
    abi: abiRegistry
  });
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};

export const getLotteryStartTime = async (lotteryId: number) => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getStartTime([new U64Value(lotteryId)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getPastWinningLotteries = async (address: Address) => {
  // console.log('ADDRESS FROM PAST WINNING IS:', new AddressValue(address));
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getPastWinningLotteries([new AddressValue(address)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  // console.log('past winning lotteries response is', response);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  // console.log('past winning lotteries parsedResponse is', parsedResponse);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    // console.log('past winning lotteries value is', value);
    return value;
  }
  return [0, initialTicketAndStake];
};

export const getUserTickets = async (address: Address, lotteryId: number) => {
  // console.log('get user tickets function called');
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getUserTickets([new AddressValue(address), new U64Value(lotteryId)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  // console.log('response is', response);

  const parsedResponse = resultsParser.parseUntypedQueryResponse(
    response
  );
  if (parsedResponse.returnCode.isSuccess()) {
    // console.log('parsed resp', parsedResponse);
    const value = parsedResponse.values.map((vv) => Array.from(vv.filter((v, i) => (i + 1) % 8 == 0)));
    // console.log('value is', value);
    return value;
  }
  return [];
};

export const getLastLotteryId = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getLastLotteryId();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getLotterySettings = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getLotterySettings();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getLotteryHistory = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getLotteryHistory();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return [];
};

export const getLotteryHistoryByIdFrom = async (idFrom: number) => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getLotteryHistoryByIdFrom([new U64Value(idFrom)]);

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  // console.log('idFrom', idFrom);

  // console.log('parsed response', parsedResponse);
  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return [];
};

export const getIsContractEnabled = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getEnabled();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return false;
};


export const getMultiplier = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getMultiplier();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getMaxTokenAmount = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getMaxTokenAmount();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getTokenId = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getTokenId();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return '';
};

export const getMinWithdraw = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getMinTokenAmountForWithdraw();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

export const getMaxNumber = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getMaxNumber();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};


export const getNumbersToExtract = async () => {
  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getNumbersToExtract();

  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(
    response,
    endpointDef
  );

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value;
  }
  return 0;
};

