import { LoadingScreen } from 'components/LoadingScreen';
import { Web3Context } from 'contexts/Web3Context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import Tilt from 'react-parallax-tilt';
import Ripples from 'react-ripples';
import { Layout } from 'components/Layout/Layout';
import { TicketCard } from './components/TicketCard';
import { PickTicketDialog } from './components/TicketCard/PickTicketDialog';
import { FixedLengthArray } from 'typesS';
import { Ticket } from 'Ticket';
import { useBuyTicket } from 'contexts/hooks/transactions/useBuyTicket';
import Timer from '../Lottery/components/Timer/Timer';
import * as scRequests from '../../contexts/scRequests';
import { WinningNumbers } from './components/WinningNumbers/WinningNumbers';
import { HistoryCarousel } from './components/HistoryCarousel';
import TicketModal from './components/TicketModal/TicketModal';
import PastWinningTickets from './components/PastWinningTickets/PastWinningTickets';
import styles from './lottery.module.scss';
import { FormatAmount } from '@multiversx/sdk-dapp/UI/FormatAmount/FormatAmount';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks/account';
import { Address } from '@multiversx/sdk-core/out';
import axios from 'axios';
import * as config from '../../config';
import { useGetTokenBalance } from 'contexts/api/useGetTokenBalance';
import { useGetTokenData } from 'contexts/api/useGetTokenData';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';


export const LotteryPage = () => {
  const account = useGetAccount();
  const { address } = useGetAccount();
  const context = useContext(Web3Context);
  const [ticket, setTicket] = useState<FixedLengthArray<[number, number, number]>>([1, 2, 3]);
  const [bet, setBet] = useState(0);
  const [duration, setDuration] = useState(0);
  const [userBalance, setUserBalance] = useState('empty string');

  const currentLotteryId = context.currentLotteryId;
  const getTokenBalance = useGetTokenBalance();

  const [tokenTicker, setTokenTicker] = useState('');
  const [tokenLogo, setTokenLogo] = useState('');
  const getTokenData = useGetTokenData();

  const handleTicketChange = (ticketPar: Ticket) => {
    setTicket(ticketPar.numbers);
  };

  const handleBet = (betVar: React.ChangeEvent<HTMLInputElement>) => {
    setBet(betVar.target.valueAsNumber);
  };

  const buyTicket = useBuyTicket(ticket, bet);

  // console.log('lotteryHist', context.lotteryHistory[9]);


  useEffect(() => {
    // console.log('context start time lottery page', context.startTime);
    const startTime = context.startTime;
    const now = new Date().getTime() / 1000;

    if (now - startTime > 3600) {
      setDuration(0);
    }
    else {
      const diff = now - startTime;
      setDuration(3600 - diff);
    }

  }, [context.currentLotteryId, context.startTime]);



  useEffect(() => {
    // awaitIfEmpty();
    getTokenBalance(account.address).then((data) => {
      if (data?.data?.balance !== undefined && data?.data?.balance !== null) {
        setUserBalance(data?.data?.balance.toString());
      }
    });
  }, [account.address, context.rewardTokenId]);

  useEffect(() => {
    getTokenData().then((data) => {
      if(data.assets?.pngUrl){
        setTokenLogo(data.assets.pngUrl);
      }
      if(data.ticker){
        setTokenTicker(data.ticker);
      }

    });
  }, [context.rewardTokenId]);

  return (

    <Layout>
      <div className='container'>
        <div className={styles.headerBox}>
          <div className='mt-3'></div>
          <div className={styles.headerText}>
            <div className=''>
              HOME
            </div>
            <div className={styles.crumbElement}> &gt; LOTTERY</div>
          </div>
          <div className='mb-2'></div>
          <h3>A fun and exciting adventure awaits</h3>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='container h-100'>
              <div className='row h-100'>
                <div className={styles.bigContainer}>
                  <div className={styles.timerContainer}>
                    <div className={styles.timerBox}>
                      <div className={styles.boxTitle} >
                        <h4>
                          Numbers picked in
                        </h4>
                      </div>
                      <Timer duration={duration} />
                    </div>
                  </div>

                  {
                    (currentLotteryId - 2 >= 0
                      && context.lotteryHistory.length !== 0
                      && context.lotteryHistory !== undefined
                      && context.lotteryHistory[0] !== undefined
                    )
                    && (
                      <>
                        <div className={`${styles.timerContainer}`}>
                          <div className={styles.timerBox}>
                            <div className={styles.boxTitle} >
                              <h4>
                                Last winning numbers
                              </h4>
                            </div>

                            {/* change this index into (x-1) when changing idFrom in context getLotteryHistoryByIdFrom(currentLotteryId - x) */}
                            {context.lotteryHistory.length !== 0 && (
                              < WinningNumbers numbers={convertToNumbers(context.lotteryHistory[9].field2)} />
                            )}

                          </div>
                        </div>
                      </>
                    )
                  }
                </div>

              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-12'>
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={styles.timerContainerRight}>
                    <div className={styles.timerBoxRight}>
                      <h4 className={` ${styles.boxTitle}`}>
                        Lottery #{context.currentLotteryId}
                      </h4>
                      <Row>
                        <Col xs={12} lg={6}>
                          <div className='d-flex flex-row align-items-center gap-2 mb-3'>
                            <div className={styles.historyTime}>
                              Lottery type
                            </div>
                            <div className={styles.historyId}>
                              {context.numbersToExtract.valueOf()}/{context.maxNumber.valueOf()}
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} lg={12}>
                          <img className='text-center' src='/media/icons/46K.svg' style={{maxWidth: '100%', width: '100%', borderRadius: '10px'}}/>
                        </Col>
                      </Row>
                      <div className='row'>
                        <div className='col-12'>
                          <div className={`card mt-3 ${styles.timerContainerRight}`}>
                            <div className={styles.historyTime}>
                              <p> Ticket </p>
                            </div>
                            <div className='d-flex flex-column gap-3'>

                              <div className='d-flex flex-row justify-content-evenly gap-3'>
                                <div className='d-flex justify-content-between flex-column align-items-center'>
                                  <div className={styles.dateRight}>
                                    <div className={styles.dateTitleRight}>{context.multiplier.valueOf()}x</div>
                                    <div className={styles.dateTextRight}>POSSIBLE WIN</div>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-between flex-column align-items-center'>
                                  <div className={styles.dateRight}>
                                    <div className={styles.dateTitleRight}>
                                      {new BigNumber(context.maxTokenAmount).dividedBy(new BigNumber(10).pow(18)).toNumber()} {tokenTicker}
                                      <img src={tokenLogo} style={{width: 'auto', height: '40px', marginLeft: '5px', marginTop: '-5px'}} />
                                    </div>
                                    <div className={styles.dateTextRight}>MAX TOKEN AMOUNT PER TICKET</div>
                                  </div>
                                </div>
                              </div>

                              < TicketCard ticket={new Ticket(ticket)} onTicketChange={handleTicketChange} />
                              <div className='d-flex flex-row gap-1'>
                                <div className="input-group">
                                  <span className={`input-group-text ${styles.bet}`} style={{padding: '5px'}}>Bet</span>
                                  <input
                                    type="text"
                                    style={{
                                      minWidth: '60px', 
                                      fontSize: '16px',
                                      WebkitAppearance: 'none',
                                      MozAppearance: 'textfield',
                                      appearance: 'textfield',
                                    }}
                                    className={address ? (`form-control ${styles.numberInput} ${styles.buttonDark}`) : (`form-control ${styles.numberInput} ${styles.buttonDark} ${styles.disabled}`)}
                                    id="inputText2"
                                    onChange={e => handleBet(e)}
                                    value={bet}
                                    max={new BigNumber(context?.maxTokenAmount).dividedBy(new BigNumber(10).pow(18)).toNumber()}
                                    onWheel={(e) => e.preventDefault()}
                                    onKeyDown={(e) => {
                                      if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                                <a
                                  className={address ? (`${styles.buttonDark} p-1`) : (`${styles.buttonDark} ${styles.disabled} p-1`)}
                                  onClick={() => setBet(context.maxTokenAmount? new BigNumber(context.maxTokenAmount).dividedBy(new BigNumber(10).pow(18)).toNumber() : 0)}
                                  title='Max'
                                  style={{width: 'auto'}}
                                >
                                  Max
                                </a>
                                <a
                                  className={address ? (`${styles.buttonDark}`) : (`${styles.buttonDark} ${styles.disabled}`)}
                                  onClick={buyTicket}
                                  title='Send ticket'
                                  style={{padding: '0 !important'}}
                                >
                                  Send ticket
                                </a>
                              </div>
                              <div className='d-flex flex-row justify-content-between align-items-center gap-3'>
                                <TicketModal />
                                <PastWinningTickets />
                              </div>
                              {
                                (address && userBalance !== undefined && !Number.isNaN(userBalance)) && (
                                  <div className='d-flex flex-row justify-content-start align-items-center gap-3'>
                                    <div className={styles.historyTitleMod}>Balance </div>

                                    <div className={styles.historyId} >
                                      {userBalance === '0' ? (
                                        <div className=''>
                                          0{' '}
                                          {tokenTicker}
                                        </div>
                                      ) : (
                                        <div className=''>
                                          {tokenTicker === 'USDC' && (
                                            new BigNumber(userBalance)
                                              .dividedBy(new BigNumber(10).pow(new BigNumber(6)))
                                              .toNumber().toFixed(4)
                                          )}
                                          {tokenTicker !== 'USDC' && (
                                            new BigNumber(userBalance)
                                              .dividedBy(new BigNumber(10).pow(new BigNumber(18)))
                                              .toNumber().toFixed(4)
                                          )}
                                          {' '}
                                          {tokenTicker}
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {context.lotteryHistory !== undefined && context.lotteryHistory.length === 0 ? (
          <>
            {/* <p className={styles.historyTime}>
              No past lotteries
            </p> */}
          </>
        ) : (
          <>
            <div className={styles.headerBox}>
              <div className='mt-3'></div>
              <div className={styles.headerText}>
                <div className=''>
                  HOME
                </div>
                <div className={styles.crumbElement}> &gt; LOTTERY HISTORY</div>
              </div>
              <div className='mb-2'></div>
              <h3>Lottery history</h3>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='container mb-5'>
                <HistoryCarousel />
              </div>
            </div>
          </>
        )
        }
      </div >

    </Layout>

  );

};

export function convertToNumbers(bigNumbers: BigNumber[]): number[] {
  return bigNumbers.map((bigNum) => bigNum.toNumber());
}